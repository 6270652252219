import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const keyDownHandler = (e, target_sentence, sendinfos) => {
    const key = e.code;
    if ((key === 'Enter') && (e.ctrlKey === true)) {
        sendinfos(target_sentence)
    }
}

/**
* If the boolean is true, return the first image, else return the second image.
* @param bool - true or false
* @returns A function that takes a boolean and returns a GatsbyImage component.
*/
export const Mic_pic = (photo) => {
        return (
            <GatsbyImage image={photo} alt={'on'} />)
};

/**
 * If the user is listening, stop listening, and change the microphone icon to the off state. If the
 * user is not listening, start listening, and change the microphone icon to the on state.
 */
// export const get_startListening = () => {
//     if (listening) {
//         SpeechRecognition.stopListening();
//         Mic_pic(false, data);
//     } else {
//         SpeechRecognition.startListening({ language: recog_lang, continuous: true })
//         Mic_pic(true, data)
//     }
// };