/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { Button, CircularProgress } from "@mui/material";
import { useMemo } from 'react';
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import MaterialReactTable from 'material-react-table';

const Log = React.memo((is_log_show) => {
    const { isLoading, error, data, refetch } = useQuery(["repoData"], () =>
        axios
            .get("http://localhost:8080/get-spoekn-sentences/")
            .then((res) => res.data)
    );

    const columns = useMemo(
        () => [
            {
                accessorKey: 'index',
                header: 'Index',
            },
            {
                accessorKey: 'sentence',
                header: 'Sentence',
            },
            {
                accessorKey: 'createdtime',
                header: 'Created time',
            }
        ],
        [],
    );
    if (isLoading) {
        return <><CircularProgress /></>
    }
    if (error) {
        return <>Error {error}</>
    }

    return (
        <>
            <Button onClick={refetch}>Reload log</Button>
            <MaterialReactTable columns={columns} data={
                data?.map(value => {
                    return (
                        {
                            index: value[0],
                            sentence: value[1],
                            createdtime: value[2],
                        }

                    )
                }
                ) ?? []}
                initialState={{
                    sorting: [
                        {
                            id: "index",
                            desc: true
                        }
                    ]
                }}
                enableClickToCopy
            />
        </>
    )
}
)

export default Log