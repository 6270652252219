import * as React from "react"
import { Stack,Alert } from '@mui/material';

type Props = {
    browserSupportsSpeechRecognition: boolean
    is_new: number
    isMicrophoneAvailable: boolean
    readyState: ReadyState
}


const Informations = (props: Props) => {
    const browserSupportsSpeechRecognition = props.browserSupportsSpeechRecognition
    const isMicrophoneAvailable = props.isMicrophoneAvailable
    const readyState = props.readyState
    const is_new = props.is_new
    return (
        <Stack sx={{ width: '100%' }} spacing={2}>
            {!browserSupportsSpeechRecognition &&
                <Alert severity="error">Your browser doesn't seem to support SpeechRecognition</Alert>
            }
            {!isMicrophoneAvailable &&
                <Alert severity="error">You need to enable microphone to use speech recognition</Alert>
            }
            {(readyState === 1 || (is_new !== 0 && is_new !== true)) &&
                <Alert severity="info">Please update KAT Subtitle</Alert>
            }
            {(!(readyState === 1) && is_new === 0) &&
                <Alert severity="error">Unable to connect the local server</Alert>
            }
        </Stack>
    )
}

export default Informations