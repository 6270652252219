import * as React from "react"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import BackspaceIcon from '@mui/icons-material/Backspace';
import TranslateIcon from '@mui/icons-material/Translate';
import SendIcon from '@mui/icons-material/Send';
import CommentIcon from '@mui/icons-material/Comment';
import { Button, Tooltip } from '@mui/material';


type Props = {
    show_translate: boolean
    is_translate: boolean
    Set_last_transcript: React.Dispatch<React.SetStateAction<string>>
    translate_sentence: (target_sentence: any) => void
    last_transcript: string
    sendinfos: (sent_sentence: any) => void
    interimTranscript: string
    finalTranscript: string
    Set_Translated_sentence: React.Dispatch<React.SetStateAction<string>>
    Translated_sentence: string
    Set_is_translate: React.Dispatch<React.SetStateAction<boolean>>
}

const Clipboard = (props: Props) => {

    const show_translate = props.show_translate
    const is_translate = props.is_translate
    const Set_last_transcript = props.Set_last_transcript
    const translate_sentence = props.translate_sentence
    const Set_is_translate = props.Set_is_translate
    const last_transcript = props.last_transcript
    const sendinfos = props.sendinfos
    const interimTranscript = props.interimTranscript
    const finalTranscript = props.finalTranscript
    const Set_Translated_sentence = props.Set_Translated_sentence
    const Translated_sentence = props.Translated_sentence

    return (<div id="div_setting">
        {show_translate &&
            <Tooltip title="Translate" arrow>
                <Button onClick={e => {
                    Set_last_transcript(last_transcript)
                    translate_sentence(last_transcript)
                    Set_is_translate(true)
                }}>
                    <TranslateIcon fontSize="large" />
                </Button>
            </Tooltip>
        }
        <Tooltip title="Send" arrow>
            <Button onClick={e => {
                if (show_translate) {
                    sendinfos(last_transcript)
                }
                else {
                    sendinfos(Translated_sentence)
                }
            }
            }>
                <SendIcon fontSize="large" />
            </Button>
        </Tooltip>



        <Tooltip title="Clear" arrow>
            <Button onClick={e => {
                if (is_translate) {
                    sendinfos("")
                    Set_Translated_sentence("")
                }
                else {
                    Set_last_transcript("")
                    sendinfos("")
                }
            }
            }>
                <BackspaceIcon fontSize="large" />
            </Button>
        </Tooltip>

        <Tooltip title="Copy" arrow>
            <Button onClick={e => {
                if (is_translate) {
                    navigator.clipboard.writeText(Translated_sentence);
                }
                else {
                    navigator.clipboard.writeText(interimTranscript ? interimTranscript : (finalTranscript ? finalTranscript : last_transcript));
                }
            }}>
                <ContentCopyIcon fontSize="large" />
            </Button>
        </Tooltip>

        {!is_translate &&
            <>
                <Tooltip title="Paste" arrow>
                    <Button onClick={e => {
                        navigator.clipboard.readText().then(data => {
                            sendinfos(data)
                            Set_last_transcript(data)
                        })
                    }
                    }>
                        <ContentPasteIcon fontSize="large" />
                    </Button>
                </Tooltip>

                <Tooltip title="Sample sentence" arrow>
                    <Button onClick={e => {
                        navigator.clipboard.readText().then(_ => {
                            // sendinfos("")
                            const data = [
                                "アリスは川辺でおねえさんのよこにすわって、なんにもすることがないのでとても退屈（たいくつ）しはじめていました。一、二回はおねえさんの読んでいる本をのぞいてみたけれど、そこには絵も会話もないのです。「絵や会話のない本なんて、なんの役にもたたないじゃないの」とアリスは思いました。そこでアリスは、頭のなかで、ひなぎくのくさりをつくったら楽しいだろうけれど、起きあがってひなぎくをつむのもめんどくさいし、ど"
                                // , "あいうえおあいうえおあいうえおあいうえおあいうえおあいうえおあいうえおあいうえおあいうえおあいうえおあいうえおあいうえおあいうえおあいうえおあいうえおあいうえおあいうえおあいうえおあいうえおあいうえおあいうえおあいうえおあいうえおあいうえお"
                                // , "123456789012345678901234567890123456789012345678901234567890123456789012345678901234567890123456789012345678901234567890"
                                // , "長持ちに 春ぞくれ行く 更衣\n 目には青葉 山ほととぎす はつ松魚"
                                , "山路を登りながら、こう考えた。智に働けば角が立つ。情に棹させば流される。意地を通せば窮屈だ。とかくに人の世は住みにくい。住みにくさが高ると、安い所へ引き越したくなる。どこへ越しても住みにくいと悟さとった時、詩が生れて、画えが出来る。"
                            ]
                            const random = Math.floor(Math.random() * data.length)

                            sendinfos(data[random])
                            Set_last_transcript(data[random])
                        })
                    }
                    }>
                        <CommentIcon fontSize="large" />
                    </Button>
                </Tooltip>
            </>
        }



    </div>)
}

export default Clipboard