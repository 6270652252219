import * as React from "react"



/**
  * It fetches the API key from the server and then sets the API key in the browser.
  */
export const fetch_apikey = (Set_DeepL_auth: (arg0: any) => void, Set_GAS_URL: (arg0: any) => void) => {
    const url = `http://localhost:8080/api_key`
    fetch(url)
        .then((response) => {
            if (!response.ok) {
                console.error('response.ok:', response.ok);
                console.error('response.status:', response.status);
                console.error('response.statusText:', response.statusText);
                throw new Error();
            }
            return response.json();
        })
        .then(data => {
            Set_DeepL_auth(data["DeepL_auth"])
            Set_GAS_URL(data["GAS_URL"])
        }
        )
        .catch(err => {
            console.log("api error")
        }
        )
}

/**
  * It takes two arguments, api_name and url, and sends a POST request to the url with the api_name
  * and url as the body.
  * @param api_name - the name of the api
  * @param url - http://localhost:8080/api_key
  */
export const update_apikey = (api_name: string, api_key: string) => {
    const url = `http://localhost:8080/api_key`
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },
        body: JSON.stringify({
            'api_name': api_name,
            'url': api_key
        })
    };
    fetch(url, requestOptions)
        .then((response) => {
            if (!response.ok) {
                console.error('response.ok:', response.ok);
                console.error('response.status:', response.status);
                console.error('response.statusText:', response.statusText);
                throw new Error();
            }
            return response.json();
        })
        .then(data => {
            console.log("sucess to update apikey")
        }
        )
        .catch(err => {
            console.log("fail to update apikey")
        }
        )
}

/**
 * It sends a text message to a server.
 * @param {string} type - string
 * @param {string} message - The message to send.
 */
export const send_texts = (type: string, message: string) => {
    // 下方互換
    // sendMessage(message)
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },
        body: JSON.stringify({
            'text_type': type,
            'text_message': message
        })
    };
    const url = "http://localhost:8080/text-message"
    fetch(url, requestOptions)
        .then((response) => {
            if (!response.ok) {
                console.error('response.ok:', response.ok);
                console.error('response.status:', response.status);
                alert(`${response.status} Error. テキストの送信に失敗しました。`)
                console.error('response.statusText:', response.statusText);
                throw new Error();
            }
        })
        .catch(err => {
            console.log("Fiale to send text")
        }
        )
}

/**
 * It's a function that returns a promise that resolves to the data returned from the fetch request.
 * @param {any} Set_All_avatar_name_and_blueprint - any
 */
export const fetch_all_avatar_name = async (Set_All_avatar_name_and_blueprint: any) => {
    return new Promise(resolve => {
        const url = `http://localhost:8080/fetch-all-avatar-name`
        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    console.error('response.ok:', response.ok);
                    console.error('response.status:', response.status);
                    console.error('response.statusText:', response.statusText);
                    throw new Error();
                }
                return response.json();
            })
            .then(data => {
                Set_All_avatar_name_and_blueprint(data)
                resolve(data)
            })
            .catch(err => {
                console.log("faile to get local avatar data")
            }
            )
    })
}

export const fetch_avatar_config = async (blueprint: string, Set_OSC_setting: any) => {
    return new Promise(resolve => {
        const url = `http://localhost:8080/fetch-avatar-config/${blueprint}`
        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    console.error('response.ok:', response.ok);
                    console.error('response.status:', response.status);
                    console.error('response.statusText:', response.statusText);
                    throw new Error();
                }
                return response.json();
            })
            .then(data => {
                Set_OSC_setting(data)
                // console.log(data)
                resolve(data)
            })
            .catch(err => {
                console.log("fail to fetch an avter config")
            }
            )
    })
}

/**
* It fetches a version number from a server and then sets a state variable based on the version
* number. Also used to check local server is available or not
* @param name - the name of the kat
* @returns a promise.
*/
export const fetch_kat_version = async (Set_is_new) => {
    return new Promise(resolve => {
        const url = `http://localhost:8080/fetch-kat-version`
        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    console.error('response.ok:', response.ok);
                    console.error('response.status:', response.status);
                    console.error('response.statusText:', response.statusText);
                    throw new Error();
                }
                return response.json();
            })
            .then(data => {
                if (Number(data) === 0.40) {
                    // console.log(data, Number(data))
                    Set_is_new(true)
                }
                else {
                    Set_is_new(data)
                }
            })
            .catch(err => {
                console.log("failt to get kat version")
            }
            )
    })
}

/**
  * 1. If the user has selected DeepL, then the function will use the DeepL API to translate the
  * sentence. 
  * 2. If the user has selected Google, then the function will use the Google API to translate the
  * sentence. 
  * 3. The function will then send the translated sentence to the server. 
  * 4. The function will then display the translated sentence on the screen.
  */
export const pre_translate_sentence = async (target_sentence, translate_type, DeepL_auth, deepl_lang,
    GAS_URL, pretrans_lang, posttrans_lang, sendinfos, Set_Translated_sentence, resetTranscript
) => {
    return new Promise(resolve => {
        {
            let url
            let text
            if (translate_type==="DeepL") {
                console.log("translate with DeepL")
                const DeepL_url = 'https://api-free.deepl.com/v2/translate'
                url = DeepL_url + "?auth_key=" + DeepL_auth + "&text=" + target_sentence + "&target_lang=" + deepl_lang;
            }
            if (translate_type==="GAS")
             {
                console.log("translate with Google")
                url = GAS_URL + "?text=" + target_sentence + "&source=" + pretrans_lang + "&target=" + posttrans_lang;
            }
            fetch(url)
                .then((response) => {
                    if (!response.ok) {
                        console.error('response.ok:', response.ok);
                        console.error('response.status:', response.status);
                        if (response.status === 456) {
                            alert(`${response.status} Error. 利用上限に達しました。`)
                        }
                        else {
                            alert(`${response.status} Error. 翻訳に失敗しました。`)
                        }
                        console.error('response.statusText:', response.statusText);
                        throw new Error();
                    }
                    if (translate_type==="DeepL") {
                        return response.json();
                    }
                    else {
                        return response.text();
                    }
                })
                .then(data => {
                    if (translate_type==="DeepL") {
                        text = data["translations"][0]["text"]
                    }
                    else {
                        text = data
                    }
                    console.log("sent", text)
                    resolve(text)
                })
                .catch(err => {
                    resetTranscript()
                }
                )
        }
    })
}

export const pre_translate_sentence0 = (target_sentence, is_deepL, DeepL_auth, deepl_lang,
    GAS_URL, pretrans_lang, posttrans_lang, sendinfos, Set_Translated_sentence, resetTranscript
) => {
    let url
    let text
    if (is_deepL) {
        console.log("translate with DeepL")
        const DeepL_url = 'https://api-free.deepl.com/v2/translate'
        url = DeepL_url + "?auth_key=" + DeepL_auth + "&text=" + target_sentence + "&target_lang=" + deepl_lang;
    }
    else {
        console.log("translate with Google")
        url = GAS_URL + "?text=" + target_sentence + "&source=" + pretrans_lang + "&target=" + posttrans_lang;
    }
    fetch(url)
        .then((response) => {
            if (!response.ok) {
                console.error('response.ok:', response.ok);
                console.error('response.status:', response.status);
                if (response.status === 456) {
                    alert(`${response.status} Error. 利用上限に達しました。`)
                }
                else {
                    alert(`${response.status} Error. 翻訳に失敗しました。`)
                }
                console.error('response.statusText:', response.statusText);
                throw new Error();
            }
            if (is_deepL) {
                return response.json();
            }
            else {
                return response.text();
            }
        })
        .then(data => {
            if (is_deepL) {
                text = data["translations"][0]["text"]
            }
            else {
                text = data
            }
            console.log("sent", text)
            sendinfos(text);
            Set_Translated_sentence(text);
            // return text
        })
        .catch(err => {
            resetTranscript()
        }
        )
}

export const save_avatar_config = async (OSC_setting) => {
    if (OSC_setting === "") return null
    return new Promise(resolve => {
        const url = `http://localhost:8080/save-avatar-config/`
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json'
            },
            body: JSON.stringify({
                'avatar_config': JSON.stringify(OSC_setting)
                // OSC_setting is obejct and it should be converted into string 
            })
        };
        fetch(url, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    console.error('response.ok:', response.ok);
                    console.error('response.status:', response.status);
                    console.error('response.statusText:', response.statusText);
                    throw new Error();
                }
                return response.json();
            })
            .then(data => {
                console.log(data)
                resolve(data)
            })
            .catch(err => {
                console.log("fail to fetch an avter config")
            }
            )
    })
}