import * as React from "react"
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Langs from "../json/lang.json"
import Input_langs from "../json/input_lang.json"
import DeepL_langs from "../json/deepl_lang.json"
import BouyomiChanClient from "../lib/bouyomichan_client"
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import Select from 'react-select'
import { Button, ButtonGroup, Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import Log from "../components/log"
import Clipboard from "../components/clipboard"
import Informations from "../components/informations"
import { StaticImage } from "gatsby-plugin-image"
import Setting from "../components/setting"
import {
  fetch_apikey, update_apikey, send_texts, fetch_kat_version,
  pre_translate_sentence
} from "../components/API_func"
import { keyDownHandler } from "../components/other_func"
import { Translate } from "@mui/icons-material"

type ts_type = "GAS" | "DeepL" | "MyMemory"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const siteDescription = data.site.siteMetadata?.description || `Desc`
  const css_active = { 'backgroundColor': "#def7ff", "color": "#1976d2" }
  const queryClient = new QueryClient();
  const langs = Langs;
  const [recog_lang, Set_recog_lang] = React.useState("ja-JP");
  const [pretrans_lang, Set_pretrans_lang] = React.useState("ja");
  const [posttrans_lang, Set_posttrans_lang] = React.useState("en");
  const [deepl_lang, Set_deepl_lang] = React.useState("EN-US");
  const [is_translate, Set_is_translate] = React.useState(false);
  const [is_setting, Set_is_setting] = React.useState(false);
  const [is_subtitle, Set_is_subtitle] = React.useState(true);
  const [is_offical_textchat, Set_is_offical_textchat] = React.useState(false);
  const [is_makevoice, Set_is_makevoice] = React.useState(false);
  const [translate_type, Set_translate_type] = React.useState<ts_type>("MyMemory");
  // const [is_deepL, Set_is_deepL] = React.useState(false);
  const [is_log_show, Set_is_log_show] = React.useState(false);
  const [is_new, Set_is_new] = React.useState(0);
  const [Translated_sentence, Set_Translated_sentence] = React.useState("");
  const [GAS_URL, Set_GAS_URL] = React.useState();
  const [DeepL_auth, Set_DeepL_auth] = React.useState();
  const [last_transcript, Set_last_transcript] = React.useState("");
  const {
    transcript,
    interimTranscript,
    finalTranscript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable
  } = useSpeechRecognition();
  const SOCKET_URL = 'ws://localhost:8080/ws'

  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
    SOCKET_URL,
    {
      share: true,
      // shouldReconnect: () => true,
    }
  );


  class Translate {
    static translate_sentence(target_sentence: string) {

      const temp = async () => {
        const result = await pre_translate_sentence(target_sentence, translate_type, DeepL_auth, deepl_lang,
          GAS_URL, pretrans_lang, posttrans_lang, sendinfos, Set_Translated_sentence, resetTranscript)
        if (result !== undefined) {
          // sendinfos(result);
          // sendinfos(result + "\n" + target_sentence);
          console.log(deepl_lang)
          sendinfos(result)
          Set_Translated_sentence(result);
          Set_last_transcript(target_sentence)
        }
      }

      const MyMemory = async () => {
        console.log("translate with MyMemory")
        const url = `https://api.mymemory.translated.net/get?q=${target_sentence}&langpair=${pretrans_lang}|${posttrans_lang}`
        const res = await fetch(url)
        const result_temp = await res.json();
        const result = result_temp.responseData.translatedText
        console.log(result)
        // sendinfos(result + "\n\n" + target_sentence);
        sendinfos(result)
        Set_Translated_sentence(result);
        Set_last_transcript(target_sentence)
      }

      if (translate_type === "MyMemory") {
        MyMemory()
      }
      else {
        temp()
      }
    }

  }

  const recog_langlist = Input_langs[0].map(lang => {
    return { "label": lang[0], "value": lang[1] }
  })
  const translate_langlist = langs[1].map(lang => {
    return { "label": lang[0], "value": lang[1] }
  })
  const translate_langlist_deepl = DeepL_langs.map(lang => {
    return { "label": lang[1], "value": lang[0] }
  })


  /**
   * If the user is listening, stop listening, and change the microphone icon to the off state. If the
   * user is not listening, start listening, and change the microphone icon to the on state.
   */
  const get_startListening = (listening: boolean) => {
    if (listening) {
      SpeechRecognition.stopListening();
      Mic_pic(false);
    } else {

      SpeechRecognition.startListening({ language: recog_lang, continuous: true })
      Mic_pic(true)
    }
  };

  const change_recoglangugae = (lang: string) => {
    const set_lang: string = (lang === "ja-JP") ? "ja-JP" : "en-US"
    Set_deepl_lang((lang === "ja-JP") ? "EN-US" : "JA")
    Set_pretrans_lang((lang === "ja-JP") ? "ja" : "en")
    Set_posttrans_lang((lang === "ja-JP") ? "en" : "ja")
    Set_recog_lang(set_lang);
    console.log(set_lang)
    Set_is_translate(true)
    if (listening) {
      SpeechRecognition.startListening({ language: set_lang, continuous: true })
      Mic_pic(true)
    };
  }

  /**
   * If the user has enabled the voice, then send the sentence to the voice. If the user has enabled the
   * subtitle, then send the sentence to the subtitle. Then reset the transcript.
   * @param sent_sentence - The sentence to be sent.
   */
  const sendinfos = (sent_sentence: string) => {
    if (is_makevoice) {
      const otiginal_sentence = sent_sentence.split("\n")[0]

      const reg = new RegExp(/^[a-zA-Z0-9!-/:-@¥[-`{-~ ]*$/);
      console.log(reg.test(otiginal_sentence))
      if (reg.test(otiginal_sentence)) {
        let utterance = new SpeechSynthesisUtterance(otiginal_sentence);
        utterance.voice = speechSynthesis
          .getVoices()
          .filter(voice => voice.name === "Google US English")[0]
        speechSynthesis.speak(utterance);
      }
      else {

        BouyomiChanClient.prototype.talk(sent_sentence)
      }

    }

    if (is_subtitle) {
      send_texts("message", sent_sentence)
    }
    if (is_offical_textchat) {
      // const temp_sent_sentence = sent_sentence.replace(/[^\x00-\x7F]/g, "?");
      // if (sent_sentence !== temp_sent_sentence) {
      // console.log("invalied character were replaced to ?\n", temp_sent_sentence)
      // }
      // send_texts("offical-text-chat", temp_sent_sentence)
      send_texts("offical-text-chat", sent_sentence)
    }
    resetTranscript();
  }

  /**
   * If the boolean is true, return the first image, else return the second image.
   * @param bool - true or false
   * @returns A function that takes a boolean and returns a GatsbyImage component.
   */
  const Mic_pic = (bool) => {
    if (bool) {
      return (
        <GatsbyImage image={getImage(data.allFile.edges[1].node.childrenImageSharp[0])} alt={'on'} />)
    }
    else {
      return (
        <GatsbyImage image={getImage(data.allFile.edges[0].node.childrenImageSharp[0])} alt={'off'} />)
    }
  };

  React.useEffect(() => {
    fetch_apikey(Set_DeepL_auth, Set_GAS_URL)
    fetch_kat_version(Set_is_new)
  }, []
  )

  React.useEffect(() => {
    if (lastMessage?.data != null) {
      if (lastMessage?.data === "start") {
        get_startListening(false)
      }
      if (lastMessage?.data === "stop") {
        get_startListening(true)
      }
      else {
        change_recoglangugae(lastMessage?.data)
      }
    }
  }, [lastMessage]);


  if (transcript.length > 500 || finalTranscript !== "") {
    if (is_translate) {
      Translate.translate_sentence(finalTranscript)
    }
    else {
      Set_last_transcript(finalTranscript)
      sendinfos(finalTranscript)
    }
  }

  return (
    <Layout location={location} title={siteTitle}>
      {/* {console.log("render")} */}
      < Seo
        title={siteTitle}
        description={siteDescription}
      />
      < Informations
        browserSupportsSpeechRecognition={browserSupportsSpeechRecognition}
        isMicrophoneAvailable={isMicrophoneAvailable}
        // readyState={readyState}
        is_new={is_new}
      />
      <Tooltip title="Push here to start voice recognition" arrow>
        <button onClick={() => { get_startListening(listening) }} id="start_button">
          {Mic_pic(listening)}
        </button>
      </Tooltip>
      <Tooltip title="Select language to use" arrow>
        <div>
          <Select defaultValue={recog_langlist[31]} options={recog_langlist} onChange={(opt) => change_recoglangugae(opt.value)} />
        </div>
      </Tooltip>
      <div >
        <TextField multiline inputProps={{ style: { fontSize: 20 } }}
          maxRows={3} fullWidth id="fullWidth" value={interimTranscript ? interimTranscript : (finalTranscript ? finalTranscript : last_transcript)}
          onKeyDown={(e) => keyDownHandler(e, interimTranscript ? interimTranscript : (finalTranscript ? finalTranscript : last_transcript), sendinfos)}
          onChange={e => {
            Set_last_transcript(e.target.value)
          }}
        />
      </div>
      {/* 認識ー＞deepl<br />

      {recog_lang}→{deepl_lang}<br />
      翻訳　前~>後<br /> 
      {pretrans_lang} {posttrans_lang}
    */}
      <Clipboard
        show_translate={true}
        is_translate={false}
        Set_last_transcript={Set_last_transcript}
        translate_sentence={Translate.translate_sentence}
        last_transcript={last_transcript}
        sendinfos={sendinfos}
        interimTranscript={interimTranscript}
        finalTranscript={finalTranscript}
        Translated_sentence={Translated_sentence}
        Set_is_translate={Set_is_translate}
      />

      {is_translate &&
        <>
          <hr />
          <div>
            <TextField multiline inputProps={{ style: { fontSize: 20 } }}
              maxRows={3} fullWidth id="fullWidth" value={Translated_sentence}
              onKeyDown={(e) => keyDownHandler(e, Translated_sentence, sendinfos)}
              onChange={e => {
                Set_Translated_sentence(e.target.value)
              }} />
          </div>
          <Clipboard
            show_translate={false}
            is_translate={true}
            Set_last_transcript={Set_last_transcript}
            Translated_sentence={Translated_sentence}
            last_transcript={last_transcript}
            sendinfos={sendinfos}
            Set_Translated_sentence={Set_Translated_sentence}

          />
        </>
      }
      <div id="div_setting">
        <ButtonGroup variant="outlined" aria-label="outlined primary button group" size="large">
          <Button style={is_translate ? css_active : null} value="Translate" onClick={() => is_translate ? Set_is_translate(false) : Set_is_translate(true)} >Translate</Button>

          <Button style={is_setting ? css_active : null} value="Setting" onClick={() => is_setting ? Set_is_setting(false) : Set_is_setting(true)}>Setting</Button>
          <Button style={is_log_show ? css_active : null} value="Setting" onClick={() => is_log_show ? Set_is_log_show(false) : Set_is_log_show(true)}>Log</Button>
        </ButtonGroup>
      </div>

      {is_translate &&
        <div >
          <div >
            <hr />
            <ButtonGroup variant="outlined" aria-label="outlined primary button group" size="large">
              <Tooltip title="MyMemory" fontSize="large" arrow >
                <Button style={translate_type === "MyMemory" ? css_active : null} onClick={() => Set_translate_type("MyMemory")}>
                  <StaticImage
                    src="../images/my.png"
                    alt="KAT"
                    placeholder="blurred"
                    layout="constrained"
                    width={55}
                    height={52}
                  />
                </Button>
              </Tooltip>
              <Tooltip title="Google translate" fontSize="large" arrow >
                <Button style={translate_type === "GAS" ? css_active : null} onClick={() => Set_translate_type("GAS")}>
                  <GTranslateIcon fontSize="large" />
                </Button>
              </Tooltip>
              <Tooltip title="DeepL" fontSize="large" arrow >
                <Button style={translate_type === "DeepL" ? css_active : null} onClick={() => Set_translate_type("DeepL")}>
                  <StaticImage
                    src="../images/DeepL_logo.svg"
                    alt="KAT"
                    placeholder="blurred"
                    layout="fixed"
                  // width={100}
                  // height={30}
                  />
                </Button>
              </Tooltip>

            </ButtonGroup>
          </div>

          {translate_type === "DeepL" &&
            <div >
              <Select defaultValue={translate_langlist_deepl[6]} options={translate_langlist_deepl} onChange={(opt) => Set_deepl_lang(opt.value)} />
              DeepL_Auth:<input type="text" defaultValue={DeepL_auth} id="DeepL_auth" onChange={(e) => {
                Set_DeepL_auth(e.target.value.replace(/\s+/g, ""));
                update_apikey("DeepL_auth", e.target.value.replace(/\s+/g, ""));
              }
              }
              />
            </div>
          }
          {translate_type === "GAS" &&
            <div>
              <Select defaultValue={translate_langlist[1]} options={translate_langlist} onChange={(opt) => Set_pretrans_lang(opt.value)} />
              <ArrowDownwardIcon fontSize="large" />
              <Select defaultValue={translate_langlist[2]} options={translate_langlist} onChange={(opt) => Set_posttrans_lang(opt.value)} />
              GAS_URL:<input type="text" defaultValue={GAS_URL} id="GAS_URL" onChange={(e) => {
                Set_GAS_URL(e.target.value.replace(/\s+/g, ""))
                update_apikey("GAS_URL", e.target.value.replace(/\s+/g, ""));
              }} />
            </div>
          }
          {translate_type === "MyMemory" &&
            <div>
              <Select defaultValue={translate_langlist[1]} options={translate_langlist} onChange={(opt) => Set_pretrans_lang(opt.value)} />
              <ArrowDownwardIcon fontSize="large" />
              <Select defaultValue={translate_langlist[2]} options={translate_langlist} onChange={(opt) => Set_posttrans_lang(opt.value)} />
            </div>
          }


          {((!GAS_URL) && (!DeepL_auth)) &&
            <>
              <p>翻訳の利用には設定が必要です。詳しくは<Link to="/Document/">Document </Link>を確認してください。</p>
              <p>You need to configure the setting to translate. Check the <Link to="/Document/">Document </Link></p>
            </>
          }
        </div>
      }

      {
        is_setting &&
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen />

          <Setting css_active={css_active} Set_is_offical_textchat={Set_is_offical_textchat} is_offical_textchat={is_offical_textchat}
            Set_is_subtitle={Set_is_subtitle} is_subtitle={is_subtitle} Set_is_makevoice={Set_is_makevoice}
            is_makevoice={is_makevoice} />
          {/* <h5><Link to="/Chatbox">Want to use VRchat offical chatbox? Use KAT for chatbox </Link></h5> */}
        </QueryClientProvider>

      }
      {
        is_log_show &&
        <QueryClientProvider client={queryClient}>
          <Log />
          <ReactQueryDevtools initialIsOpen />
        </QueryClientProvider>
      }

    </Layout >
  )
}



export default BlogIndex

/* A GraphQL query that fetches data from the site's GraphQL data layer. */
export const pageQuery = graphql`
      query {
        site {
        siteMetadata {
        title
      description
    }
  }
      allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}) {
        nodes {
        excerpt
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
      title
      description
      }
    }
  }
      allFile(filter: {base: {glob: "mic_*"}}) {
        edges {
        node {
        childrenImageSharp {
        gatsbyImageData(height: 128, width: 128)
        }
      }
    }
  }
  }
      `
