import * as React from "react"
import { Button, ButtonGroup, Icon, Tooltip, tooltipClasses, TextField, CircularProgress } from '@mui/material';
import SmsIcon from '@mui/icons-material/Sms';
import { StaticImage } from "gatsby-plugin-image"
import CampaignIcon from '@mui/icons-material/Campaign';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import Select from 'react-select'
import { useMemo, useRef } from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import axios from "axios";
import { useQuery } from "@tanstack/react-query";


const LargeTooltip = styled(({ className, ...props }) => (
  <Tooltip placement="top-end"{...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    placement: "right-end",
    fontSize: 14,
  },
}));

type Props = {
  Set_is_offical_textchat: React.Dispatch<React.SetStateAction<string>>;
  is_offical_textchat: boolean;
  Set_is_subtitle: React.Dispatch<React.SetStateAction<string>>;
  is_subtitle: boolean;
  Set_is_makevoice: React.Dispatch<React.SetStateAction<string>>;
  is_makevoice: boolean;
  css_active: any;


}

const Setting = (props: Props) => {

  const css_active: any = props.css_active
  const Set_is_offical_textchat = props.Set_is_offical_textchat
  const is_offical_textchat: boolean = props.is_offical_textchat
  const Set_is_subtitle = props.Set_is_subtitle
  const is_subtitle: boolean = props.is_subtitle
  const Set_is_makevoice = props.Set_is_makevoice
  const is_makevoice = props.is_makevoice

  const [config_value, Set_config_value] = React.useState({ text_length: NaN, line_length: NaN, sync_wait: NaN })

  const { isLoading, error, data, refetch } = useQuery(["repoData"], () =>
    axios
      .get("http://localhost:8080/kat_config/")
      .then((res) => res.data
      )
  );

  const change_setting = (new_convert_file: boolean, text_length: number, sync_wait: number, line_length: number) => {
    const url = "http://localhost:8080/kat_config"
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      },
      body: JSON.stringify({
        'text_length': text_length,
        'new_convert_file': new_convert_file,
        'sync_wait': sync_wait,
        'line_length': line_length,
      })
    };
    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          console.error('response.ok:', response.ok);
          console.error('response.status:', response.status);
          alert(`${response.status} Error. テキストの送信に失敗しました。`)
          console.error('response.statusText:', response.statusText);
          throw new Error();
        }
        return response.json()
      }
      )
      .catch(err => {
        console.log("Fiale to send text")
      }
      )
      , [text_length]
  }

  return (
    <div id="div_setting">
      {/* <ThemeProvider theme={outerTheme}>
       */}
      <hr />
      <ButtonGroup variant="outlined" aria-label="outlined primary button group" size="large">
        <LargeTooltip title="Show on offcial VRChat text chat" arrow fontSize="big">
          <Button style={is_offical_textchat ? css_active : null} onClick={() => is_offical_textchat ? Set_is_offical_textchat(false) : Set_is_offical_textchat(true)} >
            <SmsIcon fontSize="large" /></Button>
        </LargeTooltip>
        <LargeTooltip title="Show on KAT" arrow fontSize="big">
          <Button style={is_subtitle ? css_active : null} onClick={() => is_subtitle ? Set_is_subtitle(false) : Set_is_subtitle(true)} >
            <StaticImage
              src="../images/KAT.svg"
              alt="KAT"
              placeholder="blurred"
              layout="fixed"
              width={30}
              height={30}
            />
          </Button>
        </LargeTooltip>
        <LargeTooltip title="Make voice" arrow fontSize="big">
          <Button style={is_makevoice ? css_active : null} onClick={() => is_makevoice ? Set_is_makevoice(false) : Set_is_makevoice(true)}>
            <CampaignIcon fontSize="large" /></Button>
        </LargeTooltip>
        <LargeTooltip title="Change Convert file" arrow fontSize="big">

          <Button onClick={
            (opt: any) => {
              change_setting(NaN, true, NaN, NaN)
            }}
          ><ChangeCircleIcon fontSize="large" />
          </Button>
        </LargeTooltip>

      </ButtonGroup>
      <hr></hr>
      {(isLoading) && <><CircularProgress /></>}
      {(error) && <>Error {error}</>}
      {!((isLoading) || (error)) &&
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 0.5, width: '15ch' },
          }}
          noValidate
          autoComplete="off"
        >



          <LargeTooltip title="Select text length" arrow fontSize="big">
            <TextField defaultValue={data?.text_length} label="text length" id="outlined" type="number" inputProps={{ min: 128, max: 512, step: 128 }} onChange={e => Set_config_value({ ...config_value, "text_length": e.target.value })} />
          </LargeTooltip>

          <LargeTooltip title="Select line length" arrow fontSize="big">
            <TextField defaultValue={data?.line_length} label="line length" id="outlined" type="number" inputProps={{ min: 1, step: 1 }} onChange={e => Set_config_value({ ...config_value, "line_length": e.target.value })} />
          </LargeTooltip>


          <LargeTooltip title="Select sync wait" arrow fontSize="big">
            <TextField defaultValue={data?.sync_wait} label="sync wait" id="outlined" type="number" inputProps={{ min: 0.01, max: 0.50, step: 0.01 }} onChange={e => Set_config_value({ ...config_value, "sync_wait": e.target.value })} />
          </LargeTooltip>



          <LargeTooltip title="Update config" arrow fontSize="big">
            <Button onClick={() => change_setting(false, config_value.text_length, config_value.sync_wait, config_value.line_length)}>
              <SendIcon fontSize="large" /></Button>
          </LargeTooltip>
        </Box>
      }
    </div >

  )
}

export default Setting